import {FC, useEffect, useState} from "react";
import {NewOrderFormValues} from "../../../types/newOrderFormValues";
import {AllProductOutputDto} from "../../../generated-sources/openapi";
import {Description} from "../../molecules/description";
import {HeaderOptions} from "../../../types/headerOptions";
import {Field} from "formik";
import {FruitBoxSelector} from "../../molecules/fields/fruitboxSelection/fruitBoxSelector";
import {CheckBox} from "../../molecules/fields/checkBox";
import {ValueCounter} from "../../molecules/fields/valueCounter/valueCounter";
import {RadioButton} from "../../molecules/fields/radioButton";
import {TextArea} from "../../molecules/fields/textArea";
import {OrderFormFooter} from "../../molecules/orderFormFooter";
import {useCalculatePrice} from "../../../hooks/useCalculatePrice";
import {useRecoilState} from "recoil";
import {ShoppingCartState} from "../../../stores/stores";
import {ShoppingCartItemInterface} from "../../../types/shoppingCartItemInterface";
import {InfoIcon} from "../../atoms/icons/infoIcon";
import {ModalState} from "../../../stores/modalStore";
import {InfoModal} from "../../molecules/InfoModal";


interface ProductFormStepProps {
    values: NewOrderFormValues,
    setFieldValue: (field: string, value: any) => void
    handleNext: () => void;
    products: AllProductOutputDto;
    update?: boolean
}

export const ProductFormStep:FC<ProductFormStepProps> = ({values, setFieldValue, products, update= false, children}) => {
    const [, calculateShoppingCartPrice, calculateSapPrice] = useCalculatePrice()
    const [shoppingCart, setShoppingCart] = useRecoilState(ShoppingCartState);
    const [modalState, setModalState] = useRecoilState(ModalState)
    const sapProduct = products.extras[0];
    const honeyProduct = products.extras[1];
    const giftProduct = products.gift[0];

    useEffect(() => {
        if(values.extra.length === 0){
            setFieldValue('extra', products.extras.map(item => ({
                id: item.id,
                active: false,
                count: item.unit === 'Apiece' ? 1 : 0.5
            })))
        }
    }, [])

    useEffect(() => {
        if(values.fruitBox === '') {
            setFieldValue('fruitBox', products.fruitBoxes[0].id);
        }
    }, [])

    useEffect(() => {
        let cart = shoppingCart;
        values.extra.forEach((item) => {
            if(item.active){
                cart = cart.filter((cartItem: ShoppingCartItemInterface) => cartItem.id !== item.id)
                cart = [{id: item.id, amount: item.count}, ...cart];
            }else{
                cart = cart.filter((cartItem: ShoppingCartItemInterface) => cartItem.id !== item.id)
            }
        })
        setShoppingCart(cart);
        console.log(cart);
    }, [values.extra])

    useEffect(() => {
        if(values.gift && values.deliveryFrequency === '1'){
            setShoppingCart([{id: giftProduct.id,amount: 1}, ...shoppingCart]);
        }else{
            setShoppingCart(shoppingCart.filter((item: ShoppingCartItemInterface) => item.id !== giftProduct.id));
        }
    }, [values.gift, values.deliveryFrequency])

    useEffect(() => {
        if(values.fruitBox !== '') {
            let cart = shoppingCart.filter((item: ShoppingCartItemInterface) => !(products.fruitBoxes.map(product => product.id)).includes(item.id));
            cart.push({id: values.fruitBox, amount: 1})
            setShoppingCart(cart);
        }
    }, [values.fruitBox])


    return(
    <>
        <div className='md:ml-2 md:mr-16'>
            <Description
                headerTag={HeaderOptions.h1}
                headerText={'Fruitbox:'}
                descriptionText={'Gebruik de groene pijlen links en rechts om tot jouw ideale fruitbox te komen. Lust u iets niet of bent u allergisch kunt u dat opgeven op de volgende pagina.'}
            />
        </div>
        <Field type='hidden' name='fruitBox'/>
        <FruitBoxSelector value={values.fruitBox} onChange={(value => {
            setFieldValue('fruitBox', value);
        })}
                          products={products.fruitBoxes}/>
        <div className='md:ml-2'>
            <Description
                headerTag={HeaderOptions.h2}
                headerText={'Extra’s:'}
                descriptionText={'Maak hier keuze uit extra opties voor bij je abonnement'}
            />

            {products.extras.map((item, key) => (
                <div key={key}>
                    <CheckBox fieldName={`extra[${key}].active`} labelName={item.name} modalComponent={<InfoModal name={item.name} about={item.about}/>}/>
                    {values?.extra[key]?.active !== undefined && values.extra[key].active ? (<ValueCounter
                        value={values?.extra[key]?.count !== undefined ? values.extra[key].count : 0.5}
                        price={calculateSapPrice(values.extra[key].count, item.price.amount, item.price.bundleAmount, item.minAmount, item.price.additionAmount, item.price.calculationMethod)}
                        incrementMethod={() => {
                            if(values.extra[key].count < item.maxAmount){
                                setFieldValue(`extra[${key}].count`, values.extra[key].count + 0.5);
                            }
                        }}
                        decrementMethod={() => {
                            if(values.extra[key].count > item.minAmount){
                                setFieldValue(`extra[${key}].count`, values.extra[key].count - 0.5);
                            }
                        }}/>) : ''}
                </div>
            ))}
            <Description
                headerTag={HeaderOptions.h3}
                headerText={'Frequentie:'}
                descriptionText={'Maak hier een keuze hoe vaak je jouw fruitbox wilt ontvangen'}
            />
            <RadioButton value={'4'} fieldName={'deliveryFrequency'}
                         setFieldValue={setFieldValue}
                         checked={values.deliveryFrequency === '4'}>
                Wekelijks
            </RadioButton>
            <RadioButton value={'2'} fieldName={'deliveryFrequency'}
                         setFieldValue={setFieldValue}
                         checked={values.deliveryFrequency === '2'}>
                Twee wekelijks
            </RadioButton>
            {!update ?
                <RadioButton value={'1'} fieldName={'deliveryFrequency'}
                             setFieldValue={setFieldValue}
                             checked={values.deliveryFrequency === '1'}>
                    Eenmalig
                </RadioButton> : null}

            {values.deliveryFrequency === '1' ? (
                <div className={'mt-6'}>
                    <CheckBox
                        fieldName={'gift'}
                        labelName={'Cadeau? Geef een persoonlijk kaartje! (1,45)'}
                    />
                    {values.gift ? (
                        <TextArea label={'Bericht op kaartje'} props={{name: 'giftMessage'}}/>
                    ) : ''}
                </div>
            ) : ''}
        </div>
        <OrderFormFooter
            price={calculateShoppingCartPrice(products,shoppingCart,Number(values.deliveryFrequency), true, 1)}>
            {children}
        </OrderFormFooter>
    </>
)};