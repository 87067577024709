import {FC, Fragment, useEffect, useState} from "react";
import {NewOrderFormValues} from "../../../types/newOrderFormValues";
import {AllProductOutputDto, ShippingMethodOutputDto} from "../../../generated-sources/openapi";
import {Description} from "../../molecules/description";
import {HeaderOptions} from "../../../types/headerOptions";
import {TextField} from "../../molecules/fields/textField";
import {SmallCheckBox} from "../../molecules/fields/smallCheckBox";
import {ZipcodeField} from "../../molecules/fields/zipcodeField";
import {RadioButton} from "../../molecules/fields/radioButton";
import {InfoIcon} from "../../atoms/icons/infoIcon";
import {Validation} from "../../../utils/validation";
import {useGetShippingMethods} from "../../../hooks/useGetShippingMethods";
import {useRecoilState} from "recoil";
import {ShippingMethodsState, ShippingMethodState} from "../../../stores/stores";
import {ModalState} from "../../../stores/modalStore";
import {ShippingInfoModal} from "../../molecules/shippingInfoModal";
import {ShippingInfoHomeModal} from "../../molecules/shippingInfoHomeModal";
import {ShippingExternalShippingModal} from "../../molecules/shippingExternalShippingModal";
import {NoteField} from "../../molecules/fields/noteField";
import {DateField} from "../../molecules/fields/dateField";


interface ShippingFormStepProps {
    values: NewOrderFormValues,
    setFieldValue: (field: string, value: any) => void
    setFieldTouched: (field: string, value: any) => void
    handleNext: () => void;
    products: AllProductOutputDto;
    subscription: boolean
    update?: boolean
    errors: any
    touched: any
}

const ShippingInfoModelReturn = (day: string) => (
    <ShippingInfoModal shippingDay={day}/>
);

const ShippingInfoHomeModelReturn = (name: string) => (
    <ShippingInfoHomeModal name={name}/>
);

const ShippingExternalModelReturn = (name: string) => (
    <ShippingExternalShippingModal name={name}/>
);

export const ShippingFormStep: FC<ShippingFormStepProps> = ({children, values, setFieldValue, setFieldTouched, subscription, errors, touched}) => {
    const [shippingMethods, setShippingMethods] = useRecoilState(ShippingMethodsState);
    const [,setShippingMethod] = useRecoilState(ShippingMethodState)
    const [getShippingMethods] = useGetShippingMethods();
    const [modalState, setModalState] = useRecoilState(ModalState);

    const zipcodeFieldChange = async (
        zipcode: string,
        number: string,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
    ) => {
        if (Validation.zipCode.test(zipcode) && Validation.number.test(number)) {
            setFieldValue('validZipcode', true, true);
            try {
                const methods = await getShippingMethods(zipcode, number, subscription);
                setFieldValue('street', methods.street);
                setFieldValue('city', methods.city);
                setShippingMethods(methods.method);
                setFieldValue('deliveryMethod', String(methods.method[0].id));
                setShippingMethod(methods.method[0]);
            } catch (e) {
                setFieldValue('validZipcode', false, true);
                setFieldValue('street', '');
                setFieldValue('city', '');
                setFieldValue('deliveryMethod', '');
                setShippingMethods([]);
            }
        } else {
            setFieldValue('validZipcode', false, true);
            setFieldTouched('validZipcode');
            setFieldValue('street', '');
            setFieldValue('city', '');
            setFieldValue('deliveryMethod', '');
            setShippingMethods([]);
        }

        const deliveryMethodFieldChange = {

        }
    }

    return (
        <div className='md:grid md:grid-cols-2 gap-0 pr-24 md:pr-0 md:ml-2'>
            {/*personal details*/}
            <div className='md:border-r md:border-green md:pr-12'>
                <Description headerTag={HeaderOptions.h1}
                             headerText='Jouw gegevens:'
                             descriptionText='Laat hier je persoonlijke gegevens achter'/>

                <TextField name='fullName' type='text' label='Volledige naam' placeholder='John doe'/>
                <TextField name='email' type='email' label='Email' placeholder='naam@voorbeeld.nl'/>
                <TextField name='phone' type='tel' label='Telefoonnummer' placeholder='06 123 456 78'/>
                <SmallCheckBox fieldName='agreement'>
                    Hierbij accepteer ik de <a rel={'noreferrer'} href={'https://fruitboeraanhuis.nl/algemene-voorwaarden/'} target={'_blank'}
                    className={`font-bold underline ${errors.agreement && touched.agreement ? 'text-red' : 'text-black'}`}>algemene voorwaarden</a>
                </SmallCheckBox>
                <SmallCheckBox fieldName='newsPaper'>
                    Ik wil op de hoogte gehouden worden over nieuwe producten
                </SmallCheckBox>
            </div>
            {/*shipping information*/}
            <div className="md:pl-12">
                <Description headerTag={HeaderOptions.h2}
                             headerText='Bezorging:'
                             descriptionText='Vul je adres in en bekijk de bezorgopties'/>
                <ZipcodeField
                    zipcodeMethod={(zipcode, number) => zipcodeFieldChange(zipcode, number, setFieldValue, setFieldTouched)}
                    setFieldValue={setFieldValue}
                    zipcode={values.zipcode}
                    number={values.number}
                    errors={!!errors.validZipcode && !!touched.validZipcode}
                >
                    {errors.validZipcode && touched.validZipcode ?
                        <div className="col-span-5 font-medium text-red text-12 pt-2">
                            {errors.validZipcode}
                        </div> : null}
                </ZipcodeField>
                <TextField name='street' type='text' label='Straat' placeholder='' readonly/>
                <TextField name='city' type='text' label='Woonplaats' placeholder='' readonly/>
                <NoteField label={'Bestelnotitie'} props={{name: 'note'}} placeholder={'Voorkeur bij niet thuis & allergieën'}/>
                <h3 className="font-bold pr-24 text-black mb-2">Bezorgopties:</h3>
                {shippingMethods.length === 0 ?
                    <p className="font-normal pr-24 text-12 text-gray mb-6">
                        Vul eerst je postcode en huisnummer in om de bezorgopties te bekijken.
                    </p> :
                    shippingMethods.map((method: ShippingMethodOutputDto) => (
                        <Fragment key={method.id}>
                            <RadioButton
                                value={String(method.id)}
                                fieldName='deliveryMethod'
                                onChange={(value) => {
                                    setShippingMethod(method)
                                    setFieldValue('deliveryMethod', value)
                                }}
                                setFieldValue={setFieldValue}
                                checked={String(method.id) === values.deliveryMethod}>
                                <span style={{width: '235px'}}>
                                    {method.name.includes('per post') ?
                                        <span>
                                            <span>{method.name.split('per post')[0]} op</span>
                                            <span className={'float-left'}><DateField currentValue={values.shippingDate} setFieldValue={setFieldValue} name={'shipping'} placeholder={''} initialValue={method.shippingDate}/></span>
                                            <span className={'float-left mt-[10px] ml-[10px]'}>{method.name.split('per post')[1]}</span>
                                        </span> : method.name}
                                </span>
                                {method.name.includes('Ophalen') ?
                                <InfoIcon onclick={() => {setModalState({open: true, modalComponent: ShippingInfoModelReturn(method.dayDescription)})}}/> :
                                ''
                                }
                                {method.name.includes('Gratis thuisbezorgd') ?
                                    <InfoIcon onclick={() => {setModalState({open: true, modalComponent: ShippingInfoHomeModelReturn(method.name)})}}/> :
                                    ''
                                }
                                {method.name.includes('per post') ?
                                    <InfoIcon onclick={() => {setModalState({open: true, modalComponent: ShippingExternalModelReturn(method.name)})}}/> :
                                    ''
                                }
                            </RadioButton>
                        </Fragment>
                    ))
                }
            </div>
            {children}
        </div>
    )
}
