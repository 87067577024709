import {FC} from "react";
import {NewOrderFormValues} from "../../../types/newOrderFormValues";
import {Heading} from "../../atoms/basics/heading";
import {HeaderOptions} from "../../../types/headerOptions";
import Link from "next/link";
import {EditIcon} from "../../atoms/icons/editIcon";
import {Paragraph} from "../../atoms/basics/paragraph";
import {PaymentSelect} from "../../molecules/fields/paymentSelect";
import {useCalculatePrice} from "../../../hooks/useCalculatePrice";
import {AllProductOutputDto, ShippingMethodOutputDto} from "../../../generated-sources/openapi";
import {useRecoilState} from "recoil";
import {ShoppingCartState} from "../../../stores/stores";
import moment from 'moment';
import 'moment/locale/nl';
import {EbookUpSell} from "../../molecules/ebookUpSell";

interface CheckoutFormStepProps {
    values: NewOrderFormValues,
    setFieldValue: (field: string, value: any) => void
    setFieldTouched: (field: string, value: any) => void
    update?: boolean
    onboard?: boolean
    errors: any
    touched: any
    shippingMethod: ShippingMethodOutputDto
    products: AllProductOutputDto
    pathPrefix?: string,
    ebook: boolean,
    ebookChange: (value: boolean) => void
}

export const CheckoutFormStep: FC<CheckoutFormStepProps> = ({
                                                                children,
                                                                values,
                                                                setFieldValue,
                                                                setFieldTouched,
                                                                errors,
                                                                touched,
                                                                update,
                                                                onboard,
                                                                shippingMethod,
                                                                products,
    pathPrefix= '',
    ebook,
    ebookChange
                                                            }) => {
    const [, calculateShoppingCartPrice] = useCalculatePrice();

    const paymentFieldChange = (
        value: string,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
    ) => {
        setFieldValue('paymentMethod', value, true);
        setFieldTouched('paymentMethod', true, false);
    }

    const [shoppingCart, setShoppingCart] = useRecoilState(ShoppingCartState);

    return (
        <div className={'md:grid md:grid-cols-2 gap-0 pr-24 md:pr-0 md:ml-2'}>
            <div className="md:border-r md:border-green md:pr-12">
                <Heading tag={HeaderOptions.h2}>
                    Gegevens
                    <Link href={onboard ? '/onboarding/bezorging' : pathPrefix + '/bezorging'}>
                        <a>
                            <EditIcon/>
                        </a>
                    </Link>
                </Heading>
                <Paragraph className={'italic mb-1'}>{values.fullName}</Paragraph>
                <Paragraph className={'italic mb-1'}>{values.email}</Paragraph>
                <Paragraph className={'italic mb-6'}>{values.phone}</Paragraph>
                <Heading tag={HeaderOptions.h2}>
                    Bezorging
                    <Link href={onboard ? '/onboarding/bezorging' : pathPrefix + '/bezorging'}>
                        <a>
                            <EditIcon/>
                        </a>
                    </Link>
                </Heading>
                <Paragraph className={'mb-1'}>
                    {shippingMethod.visibility === 'always' ? 'Ophalen'
                        : 'Bezorging'} <span className='font-bold'>
                    {shippingMethod.visibility === 'postOrder' ?
                        moment(values.shippingDate, 'DD-MM-YYYY').locale('nl').format('dddd D MMMM') :
                        shippingMethod.deliveryDay
                    }
                </span> op:
                </Paragraph>
                {shippingMethod.visibility === 'always' ?
                    <>
                        <Paragraph
                            className={'mb-1 font-bold'}>Deventerseweg 3</Paragraph>
                        <Paragraph
                            className={'mb-6 font-bold'}>2994 LE Barendrecht</Paragraph>
                    </> :
                    <>
                        <Paragraph
                            className={'mb-1 font-bold'}>{values.street} {values.number}</Paragraph>
                        <Paragraph
                            className={'mb-6 font-bold'}>{values.zipcode} {values.city}</Paragraph>
                    </>
                }
            </div>
            <div className="md:pl-12">
                <Heading tag={HeaderOptions.h3}>
                    {values.deliveryFrequency === '1' ? values.gift ? 'Cadeau' : 'Fruitbox' : 'Abonnement'}
                    <Link href={onboard ? '/onboarding/samenstellen' : pathPrefix + '/samenstellen'}>
                        <a>
                            <EditIcon/>
                        </a>
                    </Link>
                </Heading>
                <Paragraph className={'mb-1 font-bold text-black'}>
                    1x {products.fruitBoxes.filter(item => item.id === values.fruitBox)[0].name}</Paragraph>
                {values.extra.filter(item => item.active).map((item, key) => (
                    <Paragraph key={key} className={'mb-1 font-bold text-black'}>
                        {item.count.toFixed(1).toString().replace('.',',')}L {products.extras.filter(product => product.id === item.id)[0].name}
                    </Paragraph>
                ))}
                {shippingMethod.price > 0 ?
                    <Paragraph className={'mb-1 font-bold text-black'}>
                        1x {shippingMethod.name}
                    </Paragraph>
                    : null}
                {values.gift && values.deliveryFrequency === '1' ?
                    <>
                        <Paragraph className={'mb-1 font-bold text-black'}>
                            1x Persoonlijk kaartje
                        </Paragraph>
                        <Paragraph className={'mt-3 italic'}>
                            {values.giftMessage}
                        </Paragraph>
                    </>
                    : null}
                {ebook && <Paragraph className={'mb-1 font-bold text-black'}>
                    1x E-book Smoothie recepten! {values.deliveryFrequency !== '1' && '(eenmalig)'}
                </Paragraph>}
                <h3 className="font-bold text-24 text-green mt-6 mb-6">
                    {values.deliveryFrequency === '1' ? 'Totaal: ' : null}
                    {
                        calculateShoppingCartPrice(
                            products,
                            shoppingCart,
                            Number(values.deliveryFrequency),
                            false,
                            Number(values.paymentFrequency),
                            shippingMethod.price,
                            ebook
                        )
                    }
                    {values.deliveryFrequency !== '1' ? ' per afschrijving' : null}
                </h3>
            </div>
            <div className="md:border-r md:border-green md:pr-12">
                <div className='border-t border-green'>
                    <h1 className="font-bold text-3xl text-black md:block pr-24 my-6">Betaalgegevens</h1>
                    {!update ? <>
                        <PaymentSelect
                            valueChange={(value) => paymentFieldChange(value, setFieldValue, setFieldTouched)}/>
                        {errors.paymentMethod && touched.paymentMethod ?
                            <div className="col-span-5 font-medium text-red text-12 pt-2">
                                Selecteer een bank om verder te gaan!
                            </div> : ''}
                    </> : null}
                </div>
            </div>
            <div className="md:pl-12" onClick={() => {ebookChange(!ebook)}}>
                <EbookUpSell ebook={ebook} isSubscription={values.deliveryFrequency !== '1' }/>
            </div>
            {children}
        </div>
    )
}
