import {FC, useState} from "react";
import {FormTemplate} from "./formTemplate";
import {
    Formik,
    Form
} from 'formik';
import {AllProductOutputDto, OrderInputDtoCategoryEnum} from "../../generated-sources/openapi";
import {ProductFormStep} from "../organisms/formSteps/productFormStep";
import {OrderFormValidator} from "../../validators/orderFormValidator";
import {NextIcon} from "../atoms/icons/nextIcon";
import {Button} from "../atoms/button";
import {useRecoilState} from "recoil";
import {useRouter} from "next/router";
import {ShippingFormStep} from "../organisms/formSteps/shippingFormStep";
import {CheckoutFormStep} from "../organisms/formSteps/checkoutFormStep";
import {
    InitialDataState,
    ShippingMethodState,
    ShoppingCartState,
    StepState,
    SubscriptionState
} from "../../stores/stores";
import {ShippingFormFooter} from "../molecules/shippingFormFooter";
import {CheckoutFormFooter} from "../molecules/CheckoutFormFooter";
import {useOrderCheckout} from "../../hooks/useOrderCheckout";
import {ModalState} from "../../stores/modalStore";

interface OrderFormTemplateProps {
    products: AllProductOutputDto,
    step: number,
    pathPrefix?: string,
    priceCategory?: OrderInputDtoCategoryEnum
}
export const OrderFormTemplate: FC<OrderFormTemplateProps> = ({products, step = 0, pathPrefix = '', priceCategory= OrderInputDtoCategoryEnum.NewCustomersV5}) => {
    const [initialData, setInitialData] = useRecoilState(InitialDataState);
    const [formStep, setFormStep] = useRecoilState(StepState);
    const [modalState] = useRecoilState(ModalState);
    const [isSubscription, setSubscription] = useRecoilState(SubscriptionState)
    const [shippingMethod] = useRecoilState(ShippingMethodState)
    const [shoppingCart] = useRecoilState(ShoppingCartState);
    const router = useRouter();
    const [ebook, setEbook] = useState<boolean>(false);
    const [checkout] = useOrderCheckout();
    return (
        <FormTemplate step={step} enableModal={modalState.open} modalComponent={modalState.modalComponent}>
            <Formik
                initialValues={{...initialData, nextDeliveryDay: ''}}
                onSubmit={async (values, actions) => {
                    switch (step) {
                        case 0:
                            setSubscription(values.deliveryFrequency !== '1')
                            setInitialData(values)
                            setFormStep(1)
                            await router.push(pathPrefix + '/bezorging')
                            break;
                        case 1:
                            setInitialData(values)
                            setFormStep(2)
                            await router.push(pathPrefix + '/overzicht')
                            break;
                        case 2:
                            await checkout(values, shoppingCart, priceCategory, undefined, undefined, ebook)
                            break;
                        default:
                            break;
                    }
                    actions.setSubmitting(false);
                }}
                validationSchema={OrderFormValidator(isSubscription)[step]}
            >
                {({values, setFieldValue, setFieldTouched, errors, touched}) => {
                    return (
                        <Form onChange={() => {console.log(values)}}>
                            {step === 0 ?
                                <ProductFormStep
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    handleNext={() => {
                                    }}
                                    products={products}
                                >
                                    <Button type='submit'>
                                        Volgende stap <NextIcon/>
                                    </Button>
                                </ProductFormStep>
                                : <></>
                            }
                            {
                                step === 1 ?
                                    <ShippingFormStep
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        handleNext={() => {}}
                                        products={products}
                                        subscription={isSubscription}
                                        errors={errors}
                                        touched={touched}
                                    >
                                        <ShippingFormFooter path={pathPrefix + '/samenstellen'}/>
                                    </ShippingFormStep>
                                    :
                                    <></>
                            }
                            {
                                step === 2 && shippingMethod ?
                                    <CheckoutFormStep
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        errors={errors}
                                        touched={touched}
                                        shippingMethod={shippingMethod}
                                        products={products}
                                        pathPrefix={pathPrefix}
                                        ebook={ebook}
                                        ebookChange={setEbook}
                                    >
                                        <CheckoutFormFooter label={values.deliveryFrequency === '1' ? values.gift ? 'Betaal cadeau' : 'Betaal fruitbox' : 'Eerste betaling'}/>
                                    </CheckoutFormStep>
                                    :
                                    <></>
                            }
                        </Form>
                    )
                }
                }
            </Formik>
        </FormTemplate>
    )
}