import {FC} from "react";
import {Paragraph} from "../atoms/basics/paragraph";
import Image from "next/image";
import {Button} from "../atoms/button";
import {ButtonTransparent} from "../atoms/buttonTransparent";

interface EbookUpSellProps {
    ebook: boolean,
    isSubscription: boolean
}

export const EbookUpSell: FC<EbookUpSellProps> = ({ebook, isSubscription}) => (
    <div className={'cursor-pointer border-4 border-dashed border-green rounded-24 relative mt-6 md:mt-0 w-[fit-content]'}>
        <input
            className={`form-checkbox rounded border-green border-2 focus:ring-offset-0 w-24 h-24 text-green mr-3 align-top focus:ring-green absolute top-4 right-0`}
            type='checkbox'
            checked={ebook}
            name={'ebook'}
            id={'ebook-checkbox'}
        />
        <div className={'flex mt-4'}>
            <div className={'flex-none h-[120px] w-[90px]'}>
                <Image src={'/img/fruitboer-ebook.webp'}
                       width={90}
                       height={120}
                       alt={'ebook'}/>
            </div>
            <div className={'grow mx-4'}>
                <Paragraph paddingRight={'pr-12'} className={'mb-1 font-bold text-black text-sm'}>
                    Kassakoopje: Smoothie recepten!
                </Paragraph>
                <Paragraph paddingRight={'pr-0'} className={'text-sm'}>
                    Koop nu bij je {isSubscription ? 'fruitabonnement' : 'fruitbox'} een E-book voor verschillende
                    smoothie recepten.
                </Paragraph>
            </div>
        </div>
        <div className={'mt-2 mb-6'}>
            <Paragraph paddingRight={'pr-0'}
                       className={'text-16 inline-flex flex-wrap gap-2 items-center justify-center w-full'}>
                Van <span>19,99</span> voor
                <span className={'text-18 font-bold'}> 2,99 </span>
                {!ebook ?
                    <Button type={"button"} className={'text-12 p-2 ml-0'}>
                        Voeg toe
                    </Button>
                    :
                    <ButtonTransparent type={"button"} className={'text-12 p-2'}>
                        Verwijder
                    </ButtonTransparent>
                }
            </Paragraph>
        </div>
    </div>
)