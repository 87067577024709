import {atom} from "recoil";
import {InitialInput, ShippingMethodOutputDto} from "../generated-sources/openapi";
import {ShoppingCartItemInterface} from "../types/shoppingCartItemInterface";
import moment from 'moment';
import 'moment/locale/nl';

export const InitialDataState = atom({
    key: 'initialData',
    default: {
        fullName: '',
        email: '',
        phone: '',
        zipcode: '',
        validZipcode: false,
        number: '',
        street: '',
        city: '',
        deliveryMethod: "1",
        newsPaper: false,
        agreement: false,
        fruitBox: '',
        sap: false,
        honey: false,
        sapCount: 0.5,
        deliveryFrequency: '4',
        gift: false,
        giftMessage: '',
        paymentMethod: '',
        paymentFrequency: '1',
        note: '',
        extra: [] as Array<{id: string, active: boolean, count: number}>,
        shippingDate: moment().locale('nl').add(1,'day').format('DD-MM-YYYY')
    },
})

export const SubscriptionState = atom({
    key: 'subscription',
    default: false,
})

export const StepState = atom({
    key: 'formStep',
    default: 0,
})

export const ShippingMethodState = atom({
    key: 'method',
    default: undefined as undefined|ShippingMethodOutputDto,
})

export const ShippingMethodsState = atom({
    key: 'metods',
    default: [] as ShippingMethodOutputDto[],
})

export const ShoppingCartState = atom({
    key: 'cart',
    default: [] as ShoppingCartItemInterface[],
})

export const OnboardingState = atom({
    key: 'onboarding',
    default: false,
})

export const ChangeState = atom({
    key: 'change',
    default: undefined as undefined|{id: string, token: string, email:string},
})
